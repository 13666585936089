.list.item.default {
  padding: 24px;
  margin-bottom: 24px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .main-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    .left {
      flex-grow: 1;
    }
    .right {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      flex-shrink: 0;
      .row {
        display: flex;
        flex-direction: row;
        button {
          margin: 0 12px 0 0;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .params {
      p {
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .title {
    margin-bottom: 12px;
    font-weight: bold;
  }

  .status {
    margin-bottom: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    color: #7f7f7f;
  }
  .yellow, .reviewing {color: #c48723;}
  .green, .active {color: #669933;}
  .red, .declined {color: #e60046;}
  .edit {
    //background-image: url("~src/assets/images/icons/edit_24dp.svg");
  }
  .delete {
    //background-image: url("~src/assets/images/icons/delete_24dp.svg");
  }
  .toggle {
    margin-top: auto;
    margin-left: auto;
    //background-image: url("~src/assets/images/icons/arrow_down_24dp.svg");
    transition: transform 0.2s;
    &.active {
      transform: rotateZ(180deg);
    }
  }
  .name {
    margin-bottom: 14px;
    font-weight: bold;
  }

  .details {
    margin-top: 24px;
  }
}


@media screen and (max-width: 767px) {
  .list.item.default {
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
  }
}
