.center{
    align-items: center;
}

.space{
  display: flex;
  justify-content: center;
  >.layout-corral{
    flex-basis: 1280px;
    display: flex;
  };
  .page-corral{
    flex-basis: calc($page-width + 2 * $pxl);
    padding: 0 $pxl;
    display: flex;
  }
}



jumbotron{
  margin-block: 40px 32px;
  min-height: 40px;
  .jumbotron-content{
    flex-direction: column;
    header-user-bar{
      position: absolute;
      align-self: flex-end;
    }
    .breadcrumbs{
      display: flex;
      gap:8px;
      color: #969595;
      div a{
        display: flex;
        align-items: center;
        gap:8px;
      }
    }
    h1{
      color:#000;
      font-family: "PT Serif";
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px;
      margin-block: 8px 24px;
    }
    img{
      height: 180px;
      object-fit: cover;
    }
  }

  .root-page{
    h1{
      font-size: 64px;
      line-height: 80px;
    }
    .root-menu{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-between;
      .root-menu-item{
        width: 844px;
        height: 180px;
        background-color: var(--prime);
        display: flex;
        align-items: flex-end;
        margin-bottom: 24px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        .root-menu-img{
          width: 50%;
          height: 100%;
          background-color: var(--light);
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s;
          }
        }
        .root-menu-name{
          padding: 16px;
          color: #fff;
        }

        &:hover{
          
          img{
              transform: scale(1.25);
            }
        }
      }
      .root-menu-item:first-child{
        display: flex;
        flex-direction: column;
        width: 411px;
        height: 100%;
        align-items: center;
        margin-bottom: 0;
        .root-menu-img{
          height: 532px;
          width: 100%;
        }
      }

      .root-menu-item:nth-child(3){
        flex-direction: row-reverse;
      }
      .root-menu-item:nth-child(4){
        margin-bottom: 0;
      }


      height: 588px;
      overflow: hidden;
    }
  }

}



@media screen and (max-width: 480px) {
  jumbotron .jumbotron-bg.rootpage-jumbotron .jumbotron-content .jumbotron-content-center h1{
    font-size: 50px;
    line-height: 60px;
  }
}




.content-sapce{
  flex-grow: 1;
  .content-grid{
    display: flex;
    justify-content: space-between;
    margin-bottom:  48px;

    left-content{
      display: none;
    }

    .content-grid{
      width: 1280px;
      gap:32px;
    }
    
    right-content{
     width: 258px;
     display: flex;
     flex-direction: column;
     gap: 24px;
     .right-card{
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: 24px;
      gap:16px;
      border-radius: 4px;
      border: 1px solid transparent;
      span{
        text-align: center;
      }
      &:hover{
        
        border: 1px solid var(--prime);
      }
     }
     img{
      width: 100%;
     }
    }
    .main-content{
      width: 990px;
      background-color: #FFF;
    }
    
  }
}

.this-root-page .main-content{
  background-color: transparent !important;
  .main-news{
    margin: 0 !important;
  }
}




@media screen and (min-width: 1330px){
  
}

footer{
  .footer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-inline: 48px;
    background-color: #000;
    padding-block: 32px;
    color: #ACACAC;
    .footer-contact-left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      padding-bottom: 24px;
      flex-wrap: wrap;
      flex-basis: 40%;
      margin-right: 16px;
      flex-grow: 1;
      a{
        width: 91px;
        height: 96px;
        img{
          width: 100%;
        }
      }
      .footer-contact-left-name{

        strong{
          color: #FFF; 
        }
      }

      .footer-contact-left-license{
        font-family: Golos;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
      }

    };
    .footer-contact-right{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      padding-bottom: 24px;
      flex-basis: 40%;
      margin-left: 16px;
      flex-grow: 1;
      a{
        color: #ACACAC;
      }
    }
    .footer-down{
      display: flex;
      flex-grow: 1;
      flex-basis: 100%;
      justify-content: space-between;
      padding-top: 24px;
      border-top: 1px solid #6A6868;
    }
  }
}


pages-menu-item{
  display: flex;
  flex-direction: column;
  .item{
    display: flex;
    align-items: flex-start;
    drop-down{
        margin-inline-start: -24px;
    }
    min-height: 24px;
    >a{
      margin-top: 4px;
      &.active {
        color: #000000;
      }
    }
  }

}

.pages{
  margin: 32px;
  width: calc( 100% - 64px );
}

.jumbotron-edit-field{
  margin-top: 32px;
}

.main-news{
  margin: 32px;
  .main-news-item{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 32px;
    .main-news-item-header{
      width: 100%; 
      margin-bottom: -8px;
      h3{
        color: #000;     
      }
    }

    .main-news-item-card{
      width: 250px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      overflow: hidden;
      border-radius: 4px;
      img {
        width: 100%;
        height: 308px;
        object-fit: cover;

      }
      span{
        margin: 16px 16px 0;
        color: #969595;
      }
      h4{
        margin: 16px;
      }
      &:hover{
        h4{
          color: var(--prime);
        }
      }
    }
  }
}


@media screen and (max-width: 1330px){
  .space{
    .layout-corral{
      max-width: 100vw;
    }
  }
  right-content, left-content{
    display: none;
  }
  .jumbotron-edit-field{
    display: none;
  }
}


@media screen and (max-width: 900px){
  .space{
    .layout-corral, .page-corral{
      padding: 0 24px;
    }
  }
  .copyright{
    flex-direction: column;
    gap: 24px;
  }

}