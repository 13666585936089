// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-Italic.eot');
//   src: local('Open Sans Italic'), local('OpenSans-Italic'),
//   url('../fonts/sans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-Italic.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-Italic.woff') format('woff'),
//   url('../fonts/sans/OpenSans-Italic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-Regular.eot');
//   src: local('Open Sans Regular'), local('OpenSans-Regular'),
//   url('../fonts/sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-Regular.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-Regular.woff') format('woff'),
//   url('../fonts/sans/OpenSans-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-BoldItalic.eot');
//   src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
//   url('../fonts/sans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-BoldItalic.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-BoldItalic.woff') format('woff'),
//   url('../fonts/sans/OpenSans-BoldItalic.ttf') format('truetype');
//   font-weight: bold;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-SemiBold.eot');
//   src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//   url('../fonts/sans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-SemiBold.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-SemiBold.woff') format('woff'),
//   url('../fonts/sans/OpenSans-SemiBold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-ExtraBoldItalic.eot');
//   src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
//   url('../fonts/sans/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-ExtraBoldItalic.woff') format('woff'),
//   url('../fonts/sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
//   font-weight: 800;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-LightItalic.eot');
//   src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
//   url('../fonts/sans/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-LightItalic.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-LightItalic.woff') format('woff'),
//   url('../fonts/sans/OpenSans-LightItalic.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-Bold.eot');
//   src: local('Open Sans Bold'), local('OpenSans-Bold'),
//   url('../fonts/sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-Bold.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-Bold.woff') format('woff'),
//   url('../fonts/sans/OpenSans-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-SemiBoldItalic.eot');
//   src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
//   url('../fonts/sans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-SemiBoldItalic.woff') format('woff'),
//   url('../fonts/sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
//   font-weight: 600;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-ExtraBold.eot');
//   src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//   url('../fonts/sans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-ExtraBold.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-ExtraBold.woff') format('woff'),
//   url('../fonts/sans/OpenSans-ExtraBold.ttf') format('truetype');
//   font-weight: 800;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('../fonts/sans/OpenSans-Light.eot');
//   src: local('Open Sans Light'), local('OpenSans-Light'),
//   url('../fonts/sans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
//   url('../fonts/sans/OpenSans-Light.woff2') format('woff2'),
//   url('../fonts/sans/OpenSans-Light.woff') format('woff'),
//   url('../fonts/sans/OpenSans-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/ptsans/PT_Sans-Web-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/ptsans/PT_Sans-Web-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/ptsans/PT_Sans-Web-Italic.ttf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/ptsans/PT_Sans-Web-Bolditalic.ttf');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans Narrow';
  src: url('../fonts/ptsans/PTSansNarrow-Regular.ttf');
  font-style: itnormalalic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'PT Sans Narrow';
  src: url('../fonts/ptsans/PTSansNarrow-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}




@font-face {
	font-family: 'GolosTextWebBlack';
	src: 
		url('../fonts/golos/Golos-Text_Black.woff2') format('woff2'),
		url('../fonts/golos/Golos-Text_Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GolosTextWebBold';
	src: 
		url('../fonts/golos/Golos-Text_Bold.woff2') format('woff2'),
		url('../fonts/golos/Golos-Text_Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GolosTextWebDemiBold';
	src: 
		url('../fonts/golos/Golos-Text_DemiBold.woff2') format('woff2'),
		url('../fonts/golos/Golos-Text_DemiBold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Golos';
	src: 
		url('../fonts/golos/Golos-Text_Medium.woff2') format('woff2'),
		url('../fonts/golos/Golos-Text_Medium.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Golos';
	src: 
		url('../fonts/golos/Golos-Text_Regular.woff2') format('woff2'),
		url('../fonts/golos/Golos-Text_Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}