input, select, textarea {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px var(--second-dis);
  font-family: "PT Sans";
  font-size: 20px;
  color: var(--second-act);
  background-color: #fff;
  &:hover{
    border-color: var(--second);
    outline: none;
  }
  &:focus, &:focus-visible {
    border-color: var(--prime);
    outline: none;
    
  }
  &:disabled{
    border-color: #EDEDED;
    outline: none;
  }
}

.invalid {
  input, select, .textbox {
    border-color: #e60046!important;
  }
}


.switch {
  width: 44px;
  height: 24px;
  margin: auto 0;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid var(--second);
  cursor: pointer;
  transition: background-color 0.3s;
  &:before {
    display: block;
    width: 18px;
    height: 18px;
    background-color: var(--second-dis);
    border-radius: 9px;
    transition: transform 0.3s;
    content: '';
  }
  &.active {
    background-color:var(--prime);
    border-color:var(--prime);
    &:before {
      transform: translateX(20px);
      background-color: #fff;
    }
  }
  &.active{
    &:hover {
      background-color:var(--prime-hov);
      border-color:var(--prime-hov);
      &:before {
        transform: translateX(20px);
      }
    }
    &:focus{
      background-color:var(--prime-act);
      border-color:var(--prime-act);
      &:before {
        transform: translateX(20px);
      }
    }
  }
  &:not(.active):hover {
    border-color:var(--second-hov);
    &:before {
      background-color: var(--second);
    }
  }
  &.disabled {
    opacity: 0.5;
  }
}


field-date-single{
  input{
    background: url("/assets/images/ico/calendar_grey_24.svg") no-repeat  right 12px center ;
  }
}


.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 20px;
}
.checkbox+label::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_base_24dp.svg");
}
.checkbox:checked+label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_base_24dp.svg");
}
.checkbox:not(:disabled):not(:checked)+label:hover::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_hover_24dp.svg");
}
.checkbox:not(:disabled):checked+label:hover::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_hover_24dp.svg");
}
.checkbox:not(:disabled):checked:active+label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_active_24dp.svg");
}
.checkbox:not(:disabled):not(:checked):active+label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_active_24dp.svg");
}
.checkbox:focus:checked+label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_hover_24dp.svg");
}
.checkbox:focus:not(:checked)+label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_hover_24dp.svg");
}
.checkbox:disabled:checked+label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_check_disabled_24dp.svg");
}
.checkbox:disabled:not(:checked)+label::before {
  background-image: url("~/src/assets/images/icons/checkbox/checbox_none_disabled_24dp.svg");
}




form-field-document{
  .area {
    position: relative;
    display: flex;
    height: 100px;
    padding: 16px;
    border: var(--prime) solid 1px;
    border-radius: 4px;
    overflow: hidden;
    font-size: 20px;
    &.hover {
      background-color: #e0e0e0;
    }
    p {
      margin: auto;
      color: var(--second-dis);
      text-align: center;
      span {
        color: var(--orange-2);
        cursor: pointer;
      }
    }
    input {
      display: none;
    }
    .indicator {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 16px;
      background-color: #f7f7f7;
      .label {
        margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .progress {
        height: 4px;
        background-color: #dfdfdf;
        overflow: hidden;
        .fill {
          width: 0;
          height: 100%;
          background-color: #3ca128;
        }
      }
    }
  }
  
  .values {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    .item {
      display: flex;
      flex-direction: column;
      margin-top: $p;
      align-items: flex-start;
      .doc-item-header{
        display: flex;
        align-items: flex-start;
        gap: $pxs;
        .info{
          flex-shrink: 1;
          overflow: hidden;
          max-width: 530px;
        }
        .description{
          font-size: 16px;
          line-height: 24px;
          color: var(--second);
        }
        file-ico{
          cursor: move;
        }
      }
      .doc-item-form{
        input{
          margin-bottom: $pxs;
        }
        .doc-item-form-bar{
          display: flex;
          justify-content: flex-end;
          gap:  $pxs;
          ico{
            cursor: pointer;
            display: flex;
            svg{ 
              color: var(--white);
            }
          }
          .close{
            background-color: var(--second);
            border-radius: 4px;
          }
          .update{
            background-color: var(--prime);
            border-radius: 4px;
          }
        }
      }
    }
    .fader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.6);
    }
    
    .form {
      position: relative;
      background-color: #ffffff;
      z-index: 100;
      border-radius: 12px;
      padding: $p;
    }
    
  }
}

form-field-image{
  .area {
    position: relative;
    display: flex;
    height: 100px;
    padding: 16px;
    border: var(--prime) dashed 3px;
    border-radius: 4px;
    border-image: url('/assets/images/ico/border_gr.svg') 3 round round;
    overflow: hidden;
    font-size: 20px;
    &.hover {
      background-color: #e0e0e0;
    }
    p {
      margin: auto;
      color: var(--second-dis);
      text-align: center;
      span {
        color: var(--orange-2);
        cursor: pointer;
      }
    }
    input {
      display: none;
    }
    .indicator {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 16px;
      background-color: #f7f7f7;
      .label {
        margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .progress {
        height: 4px;
        background-color: #dfdfdf;
        overflow: hidden;
        .fill {
          width: 0;
          height: 100%;
          background-color: #3ca128;
        }
      }
    }
  }
  
  .values {
    display: flex;
    column-gap: 24px;
    row-gap: 12px;
    margin-top: 8px;
    overflow: hidden;
    .item {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      align-items: flex-start;
      overflow: hidden;
      .preview {
        width: 80px;
        height: 80px;
        border: #0033661F solid 2px;
        overflow: hidden;
        cursor: move;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .trash {
        margin-left: 4px;
      }
    }
  }  
}

.field {

  flex-direction: column;
  width: 100%;
  margin: 0 0 24px;
  row-gap: 8px;

  .caption {
    color: var(--second);
    font-family: PT Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
  }
  .value {
    .error {
      margin-top: 2px;
      font-size: 0.875rem;
      color: #e60046;
    }
  }
  &.readonly {
    margin: 0 0 12px;
    .caption {
      //padding: 0;
    }
  }
}
@media screen and (max-width: 600px) {
  .field {
    margin-bottom: 8px;
    .caption {
      width: 100%;
      padding: 0;
      margin: 0 0 8px;
    }
    .value {
      width: 100%;
      margin: 0 0 16px;
    }
  }
}

auth-page{
  .authentication {
    display: flex;
    flex-direction: row;
    .logo {
      display: flex;
      width: 50%;
      flex-shrink: 0;
      height: 100%;
      border-right: #E0E0E0 solid 1px;
      text-align: center;
      color: #0071BB;
    }
  
    .form {
      display: flex;
      flex-grow: 1;
      padding: 24px;
    }
  
    .center {
      width: 100%;
      max-width: 416px;
      margin: auto;
    }
  }
  
  
  @media screen and (max-width: 959px) {
    .authentication {
      flex-direction: column;
      justify-content: center;
      .logo {
        width: 100%;
        height: auto;
        border-right: none;
      }
      .form {
        flex-grow: 0;
      }
      ::ng-deep h2 {
        text-align: center;
      }
    }
  }
  
}

auth-page, auth-modal{
  direction: ltr;

  h2 {
    margin: 0 0 24px;
    font-size: 24px;
    font-weight: normal;
  }
  
  .field {
    margin: 0 0 16px;
  
    label {
      font-size: 14px;
      color: #666666;
      line-height: 20px;
    }
  
    .checkbox {
      display: flex;
      flex-direction: row;
      gap: 12px;
      margin: 0 0 12px;
      &:last-child {margin: 0;}
      label {
        font-size: 0.875rem;
        color: #86898E;
        a {
          color: #F9B417;
        }
      }
      input {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
      }
    }
  }
  
  .error {
    margin: 0 0 16px;
    font-size: 14px;
    color: #D91519;
    text-align: center;
  }
  
  .bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    row-gap: 18px;
    .remember {
      display: flex;
      flex-direction: row;
      align-items: center;
      input {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: 2px;
        border: 1px solid #86898E;
      }
    }
    .forget {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }
  }
  
  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin: 24px 0 0;
  }
  
  
  @media screen and (max-width: 600px) {
    .bottom {
      flex-direction: column-reverse;
      button {
        width: 100%;
      }
    }
  }
  
}