table.default {
  width: 100%;
  border-collapse: collapse;
  tr {
    td {
      padding: 6px 0;
      &.caption {
        width: 232px;
        padding-right: 24px;
        color: #86898E;
        vertical-align: top;
      }
      &.value {
        vertical-align: top;
        p {
          margin: 0 0 12px;
        }
      }
    }
  }
  &.lined {
    tr {
      td {
        border-bottom: #e0e0e0 solid 1px;
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  table.default {
    tr {
      display: block;
      td {
        display: block;
        padding: 0;
        b {
          display: block;
          padding: 14px 0 0;
        }
        &.caption {
          width: 100%;
          margin: 14px 0 6px;
        }
        &.value {

        }
      }
    }
    &.lined tr td {
      border-bottom: none;
    }
  }
}
