@import "~@angular/material/prebuilt-themes/indigo-pink.css";



* {
  font-family: Golos;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}


// li {
//   list-style-image: url("~src/assets/images/icons/add_24dp.svg");
// }

// .btn {
//   font-weight: 500;
//   font-size: 0.875rem;
//   line-height: 1.125rem;
//   letter-spacing: 0.02em;
//   text-transform: uppercase;
// }

.toggle-hide{
  display: none;
}

.inline{
  display: inline-flex;
}

.center{
  align-items: center;
}

$layout-width: 1280px;
$page-width: 840px;

$pxl:40px;
$pl:32px;
$p:24px;
$ps:16px;
$pxs:8px;
$pxxs:4px;






.size-little {
  * {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  h1 {font-size: 1.5rem;}
}
.size-middle {
  * {
    font-size: 1rem;
    line-height: 20px;
  }
  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 40px;
  }
}
.size-large {
  * {
    font-size: 1.25rem;
    line-height: 24px;
  }
  h1 {font-size: 3rem;}
}
.image-hidden {
  img, svg {
    visibility: hidden !important;
  }
  *, *::after, *::before {
    background-image: none !important;
  }
}

.style-brown {
  filter: sepia(150%) !important;
}
.style-white-black {
  filter: grayscale(100%) !important;;
}
.style-black-white {
  filter: grayscale(100%) invert(100%) !important;;
}










:root {

  --smart: 688px;
  --medium: 1048px;
  --xs: 4px;
  --s: 8px;
  --m: 16px;
  --l: 24px;
  --xl: 36px;
  --xxl: 48px;

  --radius-1: 12px;
  --radius-2: 20px;
  --white: #ffffff;
  --light: #F3F3F3;


  --prime: #00589D;
  --prime-hov: #005799;
  --prime-act: #004077;
  --prime-dis: #A1CAE5;



  --second: #6C6C6C;
  --second-hov: #4D4D4D;
  --second-act: #2D2D2D;
  --second-dis: #C0C0C0;

  --dark: #1F1F1F;
  --blue-0: #2EB2E8;

 
  
}

.style-color{
  background-color: var(--light);
}

 .mat-calendar-body-selected {
  background-color: #0079c2;
}

button.mat-calendar-body-cell:hover{
  background-color: transparent;
  border: none;
  font-weight: 400;
}

button.mat-calendar-body-cell{
  font-weight: 400;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color: rgba(0, 162, 255,0.3);
  font-weight: 400;
}

.mat-calendar-body-cell-content{
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 32px !important;
  height: 32px !important;
}

.tox-promotion, .tox-statusbar__branding {
  display: none!important;
}


*, ::after, ::before {
  box-sizing: border-box;
}

* {scrollbar-color:transparent transparent; scrollbar-width:none;}
*::-webkit-scrollbar-track {background-color:transparent;}
*::-webkit-scrollbar {width:0; height:0; background-color:transparent;}
*::-webkit-scrollbar-thumb {background-color:transparent; border-radius:2rem;}


html {
  height: 100%;
  font-family: "PT Sans";
  font-size: 16px;
  -webkit-font-smoothing:antialiased;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--second-act);
  background-color: var(--light);
  font-size: 20px;
  .wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.limiter {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

p {
  color: #000;
  font-family: Golos;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

strong{
  font-weight: 600;
}

h1{
  color: var(--white);
  font-family: "PT Serif";
  font-size: 76px;
  font-style: normal;
  font-weight: 400;
  line-height: 76px; /* 100% */
  letter-spacing: 0.76px;
}

h2 {
  color: var(--second-act);
  font-family: "PT Serif";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; /* 120% */
  letter-spacing: 0.5px;
  margin:0;
}
h3 {
  font-family: "PT Serif";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 0px;
}
h4 {
  color: #6A6868;
  font-family: "PT Serif";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

a {
  text-decoration: none;
  color: var(--prime);
  cursor: pointer;
  &:hover{
    color: var(--prime-hov);
  };
}

iframe {
  width: 100%;
  height: 400px;
}


span.link {
  font-weight: normal;
  color: #86898E;
  cursor: pointer;
}

.fullwidth {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
}

.loader {
  margin-top: 32px;
  text-align: center;
  animation: fade-in-and-scale 0.5s infinite;
  &:before {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    background-image: radial-gradient(rgba(44,134,205,1), rgba(44,134,205,0));
    content: '';
  }
}

.op-63{
  opacity: 0.63;
}




@media screen and (min-width: 1330px) {
  .fullwidth {
    width: 100vw;
    margin-left: calc((1160px - 100vw) / 2);
    margin-right: 0;
  }
}



@keyframes slide-from-right {
  0% {transform: translateX(100%);}
  100% {transform: translateX(0);}
}

@keyframes slide-from-left {
  0% {transform: translateX(-100%);}
  100% {transform: translateX(0);}
}

@keyframes slide-from-top-and-fade-in {
  0% {transform: translateY(-100%); opacity: 0}
  100% {transform: translateY(0); opacity: 1}
}

@keyframes slide-from-bottom-and-fade-in {
  0% {transform: translateY(100%); opacity: 0}
  100% {transform: translateY(0); opacity: 1}
}


@keyframes fade-in-and-scale {
  0% {transform:scale(0.3); opacity:0;}
  100% {transform:scale(1); opacity:0.5;}
}

@media screen and (max-width: 480px) {
  h1{
    font-size: 50px;
    line-height: 60px;
  }
  h2{
    font-size: 36px;
    line-height: 44px;
  }
  h3{
    font-size: 24px;
    line-height: 32px;
  }
  h4{
    color:var(--second);
  }
}


@media screen and (max-width: 1330px) {

  .limiter {
    padding: 0 24px;
  }
  .fullwidth {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

}


file-ico{
  width: 52px;
  height: 60px;
  display: inline-flex;
}


modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  .modal-overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(62, 61, 64, 0.7);
    z-index: 1000;    
  }
  .modal-bar{
    position: fixed;
    max-width: 800px;
    max-height: 100vh;
    background-color: var(--white);
    border: 1px solid var(--second-dis);
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    z-index: 1000;
    overflow: hidden;
    .modal-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -1px;
      border-bottom: 1px solid var(--second-dis);
      padding: 14px 24px;
      h4{
        margin: 0;
      }
      ico{
        cursor: pointer;
      }
    }
    .modal-body{
      padding: 36px 24px;
      overflow-y: auto;
      img{
        width: 330px;
        max-width: 80vw;
      }
    }
    .modal-footer{
      display: flex;
      justify-content: space-between;
      margin-bottom: -1px;
      border-top: 1px solid var(--second-dis);
    }
  }
}


.combobox {
  position: relative;
  font-family: "PT Sans";
  border: solid 1px var(--second-dis);
  border-radius: 12px;
  font-size: 20px;
  color: var(--second-act);
  padding: 8px 12px 8px 16px;
  background: no-repeat left 16px center / 140px 16px url('~src/assets/images/icons/placeholder.svg');

  &:hover{
    border-color: var(--second);
    outline: none;
  }
  &.active {
    border-color: var(--prime);
    outline: none;
    
  }
  &:disabled{
    border-color: #EDEDED;
    outline: none;
  }

  .toggle {
    font-family: PT Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 24px;
    font-size: 20px;
    box-sizing: border-box;
    cursor: pointer;
    .caption {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      background-color: #ffffff;
      p {
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        &.faded {
          color: #7f7f7f;
        }
      }
    }
    .filter {
      display: none;
      width: 100%;
      input {
        width: 100%;
        padding: 8px 0;
        border: 0;
      }
    }



    &:after {
      display: block;
      width: 24px;
      height: 20px;
      margin-left: auto;
      background: url('~src/assets/images/icons/arrow_drop_down_grey_24dp.svg') 50% 50% no-repeat;
      content: "";
      transition: transform 0.2s;
    }
    
    &:hover:after {
      background: url('~src/assets/images/icons/arrow_drop_down_dark_24dp.svg') 50% 50% no-repeat;
    }   

  }

  &.active .toggle:after {
    background: url('~src/assets/images/icons/arrow_drop_down_24dp.svg') 50% 50% no-repeat;
  }

  .dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 1px);
    left: -1px;
    width: calc(100% + 2px);
    margin-bottom: 50px;
    background-color: #ffffff;
    border: #dfdfdf solid 1px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 3px 0;
    overflow: hidden;
    z-index: 100;

    ul {
      padding: 0;
      margin: 0;
      max-height: 280px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      .empty {
        padding: 10px 16px;
        background-color: #f7f7f7;
        color: #7f7f7f;
        .add {
          display: block;
          color: #2c86cd;
          cursor: pointer;
        }
      }

      li {
        .title {
          padding: 8px 16px;
          border-bottom: #dfdfdf solid 1px;
          margin: 0 !important;
          cursor: pointer;
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            padding: 0;
            font-size: 16px;
            &.faded {
              color: #7f7f7f;
            }
          }
          &:hover {
            background-color: #f7f7f7;
          }
          &.selected {
            background-color: #e3eff9;
          }
        }
      }
    }
  }

  &.active {
    .toggle {
      .caption {
        display: none;
      }
      .filter {
        display: block;
      }
      &::after {
        transform: rotateZ(180deg);
      }
    }
    .dropdown {
      display: block;
    }
  }
}


.values {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 8px 0 0;

    .name {
      padding-right: 8px;
      line-height: 20px;

      p {
        margin: 0;
      }

      .faded {
        color: #7f7f7f;

      }
    }

    .remove {
      width: 16px;
      height: 16px;
      padding: 0;
      margin: auto;
      border: none;
      //background: transparent url('~src/assets/images/icons/close_16dp.svg') 50% 50% no-repeat;
      cursor: pointer;
    }
  }
}
