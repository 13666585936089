.documents.list.default {
  font-size: 20px;
  .items {
    .item {
      display: flex;
      gap: 8px;
      margin: 0 0 16px;
      .value{
        display: flex;
        flex-direction: column;        
        a {
          display: inline-block;        
        }
        .description{
          font-size: 16px;
          line-height: 24px;
          color: var(--second);
        }
      }
      &:last-child {
        margin: 0;
      }
    }
    .none {
      color: #7f7f7f;
    }
  }
  .buttons {
    margin-top: 24px;
    button {
      margin-right: 24px;
      margin-bottom: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.documents.list.inline {
  .item {
    display: inline-block;
    padding: 3px 30px 3px 12px;
    margin: 0 12px 4px 0;
    border: solid 1px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    //background: #e3eff9 url('~src/assets/images/icons/open_in_new_18dp.svg') calc(100% - 8px) 50% no-repeat;
    font-size: 0.875rem;
    a {
      color: #000000;
    }
  }
}


@media screen and (max-width: 410px) {
  .documents.list.default {
    .buttons {
      button {
        width: 100%;
        margin: 0 0 12px;
      }
    }
  }
}

