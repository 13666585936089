.slider-bar {


  max-width: 720px;

  background-color: #ffffff;

  &.right-side{
    animation: slide-from-right 0.3s;
    right: 0;
    box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  &.left-side{
      animation: slide-from-left 0.3s;
      left: 0;
      box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  
  z-index: 110;

  .header {
    padding: 16px 24px;
    background: #F8F8F8;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

    .title {

      

      h2 {
        font-family: PT Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

      ico{
        cursor: pointer;
      }

    }

    .stepper {
      padding: var(--l) 0 0;
      .items {
        display: flex;
        flex-direction: row;
        gap: var(--l);
        .item {
          width: 150px;
          flex-shrink: 1;
          font-size: 14px;
          overflow: hidden;
          .num {
            position: relative;
            width: calc(100% - 13px);
            height: var(--l);
            padding: 4px 6px;
            margin: 0 0 4px;
            border-radius: 2px 0 0 2px;
            background-color: #E8E9EB;
            color: #86898E;
            font-weight: bold;
            &:after {
              position: absolute;
              left: 100%;
              top: 0;
              width: 13px;
              height: 100%;
              background: transparent url('~src/assets/images/icons/step_upcoming.svg') 100% 50% no-repeat;
              content: '';
            }
          }
          .name {
            font-size: 14px;
            color: #6c6c6c;
          }
          &.active {
            .num {
              background-color: #B88E12;
              color: #ffffff;
              &:after {
                background-image: url('~src/assets/images/icons/step_active.svg');
              }
            }
            .name {
              color: #B88E12;
              font-weight: bold;
            }
          }
          &.finished {
            .num {
              background-color: rgba(184, 142, 18, 0.2);
              color: #B88E12;
              &:after {
                background-image: url('~src/assets/images/icons/step_finished.svg');
              }
            }
            .name {
              color: #B88E12;
            }
          }
        }
      }
    }
  }

  .body {
    font-family: PT Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;


    form-frame, object-filters{
      margin: 36px 24px;
      display: block;

      .group .title {
        margin: 16px 0;
        font-family: PT Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      form-field{
        margin-bottom: 16px;
        .field {
          .caption{
          font-family: PT Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 4px;
          }
          input, textarea, .combobox, .area p{
    
            border-radius: 4px;
            padding: 8px 12px;
            font-family: PT Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
          input{
            height: 40px;
          }

        }
      }
    }
  }




  [footer] {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    background: #F8F8F8;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);

    button{
      font-family: PT Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.56px;
      text-transform: uppercase;
      border-radius: 4px;
      padding: 8px 20px;
    }
    
    .left {
      .reset {
        color: #6C6C6C;
        cursor: pointer;
      }

      .notice {
        font-family: PT Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .right {
      margin-left: auto;
      display: flex;
      button {
        margin-left: var(--l);
      }
    }
  }
}

.slider-overlay {

  background-color: rgba(0, 0, 0, 0.37);

}


@media screen and (max-width: 767px) {
  .slider-bar {
    .header, .footer {
      padding: var(--m);
    }

    [footer] {
      .left {
        font-size: 14px;
      }
      .right {
        button {
          padding: var(--s) var(--m);
          margin-left: var(--m);
        }
      }
    }
  }
}