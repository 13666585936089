header-user-bar{
    cursor: pointer;
    .user {

        position: relative;
        display: flex;
        padding: 11px;
        height: 64px;
        justify-content: center;
        background-color: #fff;
        border-radius: 32px;  
        border: 1px solid var(--second-dis);
      
        .avatar {
          width: 38px;
          height: 38px;
          color: var(--prime);
          border: 1px solid var(--prime);
          background: #fff;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          .initials {
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      
        .name{
          margin: 8px 4px 0 12px;
          color: var(--second-act);
          white-space: nowrap;
      
        }
      
      
        .dropdown{
            right: 16px;
            top: 48px;
        }
      
          drop-down {
            display: flex;
            margin-top: 10px;
            .on{
                svg{
                    color:var(--dark)
                }
            }
          }
      
      
      }
      @media screen and (max-width: 1330px) {
        .user {
          .avatar {
            background-color: var(--white);
            margin-right: 18px;
          }
          .name{
            display: none;
          }
        }
        
    }    
}
