.tabs.default {
  display: flex;
  margin-bottom: 32px;
  max-width: 100%;
  overflow: auto;
  box-shadow:  inset 0px -1px var(--second-dis);
  -webkit-overflow-scrolling: touch;
  &.simple {
    margin-bottom: 24px;
  }
  >div{
    margin-right: -1px;
    button {
      padding: 8px 20px;
      background: #ffffff;
      border-radius: 4px 4px 0 0;
      border: var(--prime) solid 1px;
      color: var(--prime);
      font-family: PT Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      span {
        white-space: nowrap;
      }
      &:last-child {

      }
      &.active {
        color: #ffffff;
        background-color: var(--prime);
        border-color: var(--prime)
      }
      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
      &:hover {
        background-color:  var(--prime-act);
        border-color:  var(--prime-act);
        color: #ffffff;
      }
    }
  }

}
