.dropdown {
  position: absolute;
  width: 250px;
  right: 24px;
  top: 62px;
  z-index: 10;
  z-index: 1000;

  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  .menu {
    display: flex;
    flex-direction: column;
    border: var(--second-dis) solid 1px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

    .type {
      display: flex;
      flex-direction: column;
      background-color: var(--white);
      border-bottom: var(--second-dis) solid 1px;
      overflow: hidden;
      &:first-child {
        border-radius:  4px  4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px  4px;
        .row {
          border-bottom: none;
        }
        a, span {
          border-bottom: none;
        }
      }
      >&:hover {
        background-color: var(--light);
      }
      
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 20px;
        
        column-gap: 12px;
        cursor: pointer;
        color: var(--second-act);

        .title {

        }



        a, span {
          display: block;
          padding: 12px 16px;
          color: #000000;
          cursor: pointer;
          border-bottom: #E0E0E0 solid 1px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:last-child {
          a, span {
            border-bottom: none;
          }
        }
      }

      .sub-row {
        padding: 12px 16px 12px 32px;
        border-top: var(--second-dis) solid 1px;
        color: var(--second);
        background-color: var(--white);

        cursor: pointer;
        &:hover {
          background-color: var(--light);
        }
      }
    }
  }
}
