pages-menu{
  display:flex;
  flex-direction: column;
  padding-left: 0;
}
pages-menu{
  padding-inline-start: 24px;
}





header{

  .header.layout-corral{
    flex-direction: column;
  
      .header-top-menu{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;
        height: 40px;
        background-color: var(--prime);
        .header-top-menu-ref{
          display: flex;
          align-items: end;
          color: #FFF;
          gap: 8px;
          cursor: pointer;
        };
        locale{
          img{
            width: 27px;
          }
          .dropdown{
            left: -198px;
            top: 29px;
            img{
              border: 1px solid var(--second-dis);
            }
          }
        }
      }

      .header-contact{
        display: flex;
        justify-content: space-between;
        height: 128px;
        padding-inline: 48px;
        background-color: var(--light);
        .header-contact-left{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          
          a{
            width: 91px;
            height: 96px;
            img{
              width: 100%;
            }
          }
          div{
            width: 280px;
            span{
              color: #6A6868;
            }
          }
        };
        .header-contact-right{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          color: #6A6868;
          div{
            display: inline-flex;
            align-items: center;
            gap: 8px;
            ico{
              margin-left: 16px;
            }
          }
        }
      }

      pages-menu.lvl-0{
        flex-direction: row;
        width: 100%;
        padding-inline: 48px;
        background-color: #FFF;
        position: relative;
        >pages-menu-item{
          .item{
            padding: 14px 24px;
            drop-down{
              display: none;
            }
            a{
              color: #000;
              margin-top: 0;

            }
            &.parent,&.current{
              padding-bottom: 10px;
              a{
                color: var(--prime);
                margin-top: 0;
              }
              border-bottom: 4px solid var(--prime);
            }
            &:hover{
              a{
                color: var(--prime);
              }
            }

          }
          pages-menu{
            display: none;
          }

          pages-menu{
            position: absolute;
            background-color:#FFF;
            top: 52px;
            border-top: 2px solid var(--light);
            z-index:1;
            padding: 14px 0;
            .item{
              padding: 4px 24px;
              border-bottom: none !important;
            }

          };

        }
      }

    
  };

}


@media (hover: hover) and (pointer: fine) { 
  /* has mouse */ 

  header{
    .header.layout-corral{  
        pages-menu.lvl-0{
          >pages-menu-item:hover{  
            pages-menu{
                display: flex;  
            };  
          }
        }      
    };  
  }
}
 
@media (hover: none) and (pointer: coarse) { 
  /* fore tuchscreen */ 
  header{
    .header.layout-corral{  
        pages-menu.lvl-0{
          >pages-menu-item{  
            pages-menu{
              &.selected{
                display: flex;
              }  
            };  
          }
        }      
    };  
  }
}




a.logo{
  img{
    width:114px; 
    height: 80px;
  }
}






slider[side="left"]{
  .slider-bar{
    background-color: var(--prime-act);
    padding: 40px 48px;
    width: 480px;
    max-width: 100vw;
    .header{
      background-color: var(--prime-act);
      box-shadow: none;
      padding: 0 0 28px;
      .title{
        ico svg{
          color: var(--white);
        }
      }

    }
    .body{
      pages-menu{
        ico{

          margin-top: 20px;
          svg{
            color: var(--white);
          }
        }
        a{
          font-family: PT Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: var(--white);
          margin: 20px 0 2px 20px;
        }

        &.top-menu{
          margin-bottom: 20px;
          &>pages-menu-item>.item>a{
            font-family: PT Sans Narrow;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.24px;
          }
        }
        .current{
          border-bottom: 2px solid var(--prime-dis);
        }
      }
    }
  }

  .btn-menu{
    display: flex;
    flex-direction: column;
    gap:40px;
    padding: 20px 0 0 ;
      border-top: 1px solid var(--prime);
    a{
      font-family: PT Sans Narrow;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.24px;
      color: #FFF;
    }
    locale{
      img{
        width: 27px;
      }
      .dropdown{
        left: 48px;
        top:-24px;
        img{
          border: 1px solid var(--second-dis);
        }
      }
    }
  }

}








@media screen and (max-width: 1330px){

}

@media screen and (max-width: 900px){
  slider[side="left"]{
    .slider-bar{
      padding: 40px 32px;
    }
  }
}




@media screen and (min-width: 1330px){

}
slider-menu{
  pages-menu:not(.top-menu,.open){
    display: none;
  }
}
right-content{


  
}





