.administration-page{
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 32px;
    h2{
        color: var(--second-act);
        margin-bottom: 48px;
    }

}


.site-admin-control{

    .site-admin-control-toggle{
      display: flex;
      align-items:flex-start;
      gap: 16px;
      cursor: pointer;
      margin-bottom: 32px;
      switch{
        margin-top: 2px;
      }
    }
    .site-admin-page-block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      border-top: 1px solid var(--second-dis);
      border-bottom: 1px solid var(--second-dis);
      div{
        display: flex;
        align-items: center;
      }
      .site-admin-company{
        font-weight: 700;
      }      
    }
    ico.page-lable{
      background-color: var(--prime);
      border-radius: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }


}
administrate-site-pages{
  >pages-tree{
    display: block;
    margin-bottom: 128px;
  }


  @media screen and (max-width: 1330px) {
    .page-control {
      display: none;
    }
    .logo, .page-lable{
      display: none !important;
    }
  }
}
  
ico.page-control{
  cursor: pointer; 
  svg{
    color:var(--second);
    &:hover{
      color: var(--second-act);
    }
  }
}
pages-tree{
    
  .item:not(.home){
      .bar{
        padding-left: 50px;

      }
      .item .bar{
        padding-left: 100px;
      }
      .item .item .bar{
        padding-left: 150px;
      }
      .item .item .item .bar{
        padding-left: 200px;
      }

      .item .item .item .item .bar{
        padding-left: 250px;
      }

      .item .item .item .item .item .bar{
        padding-left: 300px;
      }
  }



  .item {
    flex-direction: column;
    align-items: stretch;
    font-family: PT Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    .bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: solid var(--second-dis) 1px;

      .left {
        cursor: pointer;
        width: 24px;
        flex-shrink: 0;
      }
      .mid {
        flex-grow: 1;
        padding: 0 16px;
        .info {
          display: flex;
          flex-direction: row;
          align-items: center;
          .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            margin-right: 16px;
            border-radius: 100px;
            background-color: var(--prime);
            color: #ffffff;
            cursor: move;
            &:hover{
              background-color: var(--prime-act);
            }
            span{
              display: none;
            }
          }
          .name {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 20px 0;
              a {
                color: var(--dadk);
                &:nth-child(2){
                  color: var(--second);
                }
              }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 1;
        gap: 24px;
      }
    }
  }

  




}



administrate-licence{
  font-family: PT Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark);
  .default{
    
    .caption{
      color: var(--second);
    }
    .value{
      &.ok{
        color: #14A92D
      }
      &.warn{
        color: #E51F30
      }
    }
  }
  h3{
    font-family: PT Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 24px 0 16px;
  }
  p{
    font-size: 16px;
  }
}

users-list{
  .bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    border-top: var(--second-dis) solid 1px;
    border-bottom: var(--second-dis) solid 1px;
    ico{
      cursor: pointer;
    }
    .title {
      flex-grow: 1;
      font-weight: bold;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      flex-shrink: 0;
    }
  }
  
  users-list-item {
    display: flex;
    align-items: center;
    height: 72px;
    border-bottom: var(--second-dis) solid 1px;
    .item{
      display: flex;
      width: 100%;
      .left{
        .avatar{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          margin-right: 16px;
          border-radius: 100px;
          background-color: var(--prime);
          color: #ffffff;
          overflow: hidden;
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      .middle{
        .user-data{
          .name{
            &::after{
              content: '•';
              color: var(--second);
              margin: 0 8px;
            }
          }
          .Administrator{
            color: #E51F30;
          }
          .Editor{
            color: #A39600
          }
          .User{
            color: #14A92D;
          }
        }
        .position{
          color: var(--second);
        }
      }
      .right{
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;
      }
      ico{
        cursor: pointer;
        svg{
          color: var(--second) ;
        }
        &:hover{
          svg{
            color: var(--second-act) ;
          }
        }
      }
    }

  }
}